<template>
<Header />
  <div class="about container">
    <div class="card">
      <h1 class="text-center">Tentang Situs ini</h1>
      <p>Situs ini dipergunakan untuk menyimpan data penting khusus untuk keluarga pak <b>Pandi</b>.</p>
      <p>Dibuat dengan VueJs dengan database Firebase</p>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import Header from '@/components/Header.vue'

export default {
  name: 'About',
  components: {
    HelloWorld,
    Header
  }
}
</script>